import { defineMessages } from 'react-intl';

export default defineMessages({
    pageDescription: {
        id: 'quoteandbind.views.payment-details.Policy Summary and Billing',
        defaultMessage: 'Policy Summary and Billing'
    },
    deductibles: {
        id: 'quoteandbind.views.payment-details.Deductibles',
        defaultMessage: 'Deductibles'
    },
    description: {
        id: 'quoteandbind.ho.directives.ho-coverages.Description',
        defaultMessage: 'Description'
    },
    limit: {
        id: 'quoteandbind.ho.directives.ho-coverages.Limit',
        defaultMessage: 'Limit'
    },
    premium: {
        id: 'quoteandbind.ho.directives.ho-coverages.Premium',
        defaultMessage: 'Premium'
    },
    limitedEarthquake: {
        id: 'quoteandbind.ho.directives.ho-coverages.coverage.Limited Coverage for Earthquake',
        defaultMessage: 'Limited Coverage for Earthquake'
    },
    included: {
        id: 'quoteandbind.ho.directives.ho-coverages.Included',
        defaultMessage: 'Included'
    },
    earthquakeCoverageLimit: {
        id: 'quoteandbind.ho.directives.ho-coverages.Earthquake Coverage Limit',
        defaultMessage: '$5,000 / $1,500'
    },
    actualLoss: {
        id: 'quoteandbind.ho.directives.ho-coverages.Actual Loss Sustained up to 12 Months',
        defaultMessage: 'Actual Loss Sustained up to 12 Months'
    },
    policyDeductible: {
        id: 'quoteandbind.ho.directives.ho-coverages.Policy Deductible',
        defaultMessage: 'Policy Deductible'
    },
    deductibleAmount: {
        id: 'quoteandbind.ho.directives.ho-coverages.AMOUNT',
        defaultMessage: '&lt;span&gt;AMOUNT&lt;/span&gt;&lt;b&gt;{amount}&lt;/b&gt;'
    },
    earthquakeDeductible: {
        id: 'quoteandbind.ho.directives.ho-coverages.Earthquake Deductible',
        defaultMessage: 'Earthquake Deductible'
    },
    homeProtectionDeductible: {
        id: 'quoteandbind.ho.directives.ho-coverages.Home Systems Protection Deductible',
        defaultMessage: 'Home Systems Protection Deductible'
    },
    identityFraudDeductible: {
        id: 'quoteandbind.ho.directives.ho-coverages.Identity Fraud Expense Deductible',
        defaultMessage: 'Identity Fraud Expense Deductible'
    },
    deductibleAmountHeader: {
        id: 'quoteandbind.ho.directives.ho-coverages.Amount',
        defaultMessage: 'Amount'
    },
    dateChangedModalTitle: {
        id: 'quoteandbind.controllers.PaymentDetailsCtrl.We have updated your policy effective date',
        defaultMessage: "We've updated your policy effective date"
    },
    dateChangedModalMessage: {
        id: 'quoteandbind.controllers.PaymentDetailsCtrl.The policy effective date you originally selected',
        defaultMessage: 'Your selected policy effective date is no longer available. The date in your quote has been updated to &lt;b&gt;{updatedEffectiveDate}&lt;/b&gt;. You can select a different policy effective date by &lt;a onClick=jumpToYourInfo&gt;returning to the Your Info page&lt;/a&gt;'
    },
    paymentMethodOneTime: {
        id: 'quoteandbind.views.payment-details.Bank Account (One-time Withdrawal)',
        defaultMessage: 'Bank Account (One-time Withdrawal)'
    },
    paymentMethodCreditCard: {
        id: 'quoteandbind.views.payment-details.Credit Card or Debit Card',
        defaultMessage: 'Credit Card or Debit Card'
    },
    paymentMethodRecurring: {
        id: 'quoteandbind.views.payment-details.Bank Account (Recurring)',
        defaultMessage: 'Bank Account (Recurring)'
    },
    selectPaymentMethodOption: {
        id: 'quoteandbind.views.payment-details.-- Select a Payment Method --',
        defaultMessage: '-- Select a Payment Method --'
    },
    selectPaymentMethod: {
        id: 'quoteandbind.views.payment-details.Select A Payment Method',
        defaultMessage: 'Select A Payment Method'
    },
    threePayBankAccount: {
        id: 'quoteandbind.views.payment-details.Three Pay.Bank Account',
        defaultMessage: '* Your bank account will be debited for the amount of your initial payment. You will then be billed for any additional installments.'
    },
    nsfFee: {
        id: 'quoteandbind.views.payment-details.NSF fee',
        defaultMessage: '* A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.'
    },
    threePayWithCreditCard: {
        id: 'quoteandbind.views.payment-details.3 Pay With CreditCard',
        defaultMessage: '* Your credit card or debit card will be debited for the amount of your initial payment. You will then be billed for any additional installments.'
    },
    monthlyRecurringNotice: {
        id: 'quoteandbind.views.payment-details.Monthly Recurring Notice',
        defaultMessage: "We're currently not offering the ability to setup Monthly Recurring Payments online. Please contact our customer service center at {phoneNumber} and supply your quote number. Our customer service representatives can setup your recurring payments. Alternatively, you can select another payment plan to proceed."
    },
    yourQuoteNumber: {
        id: 'quoteandbind.views.payment-details.Your Quote Number',
        defaultMessage: 'Your Quote Number is {quoteNumber}.'
    },
    callCenterBusinessHours: {
        id: 'quoteandbind.views.payment-details.Call Center Business Hours',
        defaultMessage: 'Call Center Business Hours\nMonday to Friday: 8:00 am - 4:00 pm PT'
    },
    totalPaymentDueTodayKey: {
        id: 'quoteandbind.views.payment-details.Total Payment Due Today',
        defaultMessage: 'Total Payment Due Today:'
    },
    totalPaymentDueDate: {
        id: 'quoteandbind.views.payment-details.Total Payment Due Date',
        defaultMessage: 'Total Payment Due {dueDate}:'
    },
    creditCardFee: {
        id: 'platform.paymentdetails.credit-card-details.A convenience fee is included within the payment due today.',
        defaultMessage: '* A {convenienceFeeWithSymbol} credit or debit card processing fee is included within the payment amount due today.'
    },
    policyTotal: {
        id: 'quoteandbind.views.payment-details.Policy Total',
        defaultMessage: 'Policy Total'
    },
    redirectNotice: {
        id: 'quoteandbind.views.payment-details.You will be redirected to a secure payment processing website to complete your transaction',
        defaultMessage: 'You will be redirected to a secure payment processing website to complete your transaction.'
    },
    authorization: {
        id: 'quoteandbind.views.payment-details.Authorization',
        defaultMessage: 'Authorization'
    },
    authorizationAttempt: {
        id: 'quoteandbind.views.payment-details.After this attempt',
        defaultMessage: 'After this attempt, there will be {authorizationAttempts} authorization attempts remaining.'
    },
    pleaseSelectPayment: {
        id: 'quoteandbind.views.payment-details.Please select a preferred payment method before completing your insurance policy purchase.',
        defaultMessage: 'Please select a preferred payment method before completing your insurance policy purchase.'
    },
    purchaseNow: {
        id: 'quoteandbind.views.payment-details.Purchase Now',
        defaultMessage: 'Purchase Now'
    },
    proceedToPayment: {
        id: 'quoteandbind.views.payment-details.Proceed to Payment',
        defaultMessage: 'Proceed to Payment'
    },
    continue: {
        id: 'quoteandbind.views.payment-details.Continue',
        defaultMessage: 'Continue'
    },
    serviceTemporarilyUnavailable: {
        id: 'quoteandbind.views.policy-summary.Online Service Temporarily Unavailable',
        defaultMessage: 'Online Service Temporarily Unavailable'
    },
    onlineServiceUnavailable: {
        id: 'quoteandbind.views.policy-summary.Online Service Unavailable',
        defaultMessage: 'The online service to complete this transaction is temporarily unavailable.'
    },
    onlinePaymentPlanUnavailable: {
        id: 'quoteandbind.views.policy-summary.Online Payment Plan Unavailable',
        defaultMessage: '&lt;span&gt;To complete your purchase and enroll in our Monthly Automated Recurring Payment Plan, contact our Quote Specialists directly at {phoneNumber}. Please have your &lt;b&gt;Quote Number {quoteNumber}&lt;/b&gt; ready.\n\n To complete your purchase online, please select another payment plan.&lt;/span&gt;'
    },
    savingSubmission: {
        id: 'quoteandbind.views.policy-summary.Saving submission..',
        defaultMessage: 'Saving submission..'
    },
    processingPayment: {
        id: 'quoteandbind.views.policy-summary.Processing Payment',
        defaultMessage: 'Processing Payment'
    },
    loading: {
        id: 'quoteandbind.views.policy-summary.Loading',
        defaultMessage: 'Loading...'
    },
    authorizationParagraph: {
        id: 'quoteandbind.views.policy-summary.authorizationParagraph',
        defaultMessage: 'I authorize Wawanesa General Insurance Company ("Wawanesa") to debit the bank account indicated in this Authorization Form on the payment date and amount listed above. I understand that because this is an electronic transaction, funds may be withdrawn from my account as soon as the payment date listed above. If an ACH transaction is rejected for nonsufficient funds (NSF) I understand that I will be charged an NSF fee. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I represent that I am an authorized user of this account and will not dispute this one-time electronic fund transfer from my account, so long as the transaction corresponds to the terms indicated in this Authorization Form.'
    },
    iAgree: {
        id: 'quoteandbind.views.policy-summary.I AGREE',
        defaultMessage: 'I AGREE'
    },
    authValidation: {
        id: 'quoteandbind.views.policy-summary.Auth Validation',
        defaultMessage: 'Please indicate you\'ve read and agree to the authorization statement to purchase.'
    },
    jewelryWatcherAndFurs: {
        id: 'quoteandbind.views.policy-summary.jewelryWatcherAndFurs',
        defaultMessage: 'Jewelry, Watches and Furs'
    },
    money: {
        id: 'quoteandbind.views.policy-summary.money',
        defaultMessage: 'Money'
    },
    firearms: {
        id: 'quoteandbind.views.policy-summary.firearms',
        defaultMessage: 'Firearms'
    },
    portableElectronicEquipment: {
        id: 'quoteandbind.views.policy-summary.portableElectronicEquipment',
        defaultMessage: 'Portable Electronic Equipment'
    },
    silverwareGoldwarePewterwareAndPlatinumware: {
        id: 'quoteandbind.views.policy-summary.silverwareGoldwarePewterwareAndPlatinumware',
        defaultMessage: 'Silverware, Goldware, Pewterware and Platinumware'
    },
    securities: {
        id: 'quoteandbind.views.policy-summary.securities',
        defaultMessage: 'Securities'
    },
    creditCard: {
        id: 'quoteandbind.views.policy-summary.creditCard',
        defaultMessage: 'Credit Card'
    },
    earthquake: {
        id: 'quoteandbind.views.policy-summary.earthquake',
        defaultMessage: 'Earthquake'
    },
    increasedLimitsOnBusinessProperty: {
        id: 'quoteandbind.views.policy-summary.increasedLimitsOnBusinessProperty',
        defaultMessage: 'Increased Limits on Business Property'
    },
    increasedSpecialLimitsOfLiabilityOnProperty: {
        id: 'quoteandbind.views.policy-summary.increasedSpecialLimitsOfLiabilityOnProperty',
        defaultMessage: 'Increased Special Limits of Liability on Property'
    }
});
