/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import appConfig from 'app-config';
import { IntlContext, useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useMediaQuery } from 'react-responsive';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WMICCheckbox } from 'wmic-components-platform-react';
import cx from 'classnames';
import {
    WMICModal,
    WMICPremiumBoxSummary,
    WMICCustomInput,
    WMICCustomRadioButton,
    WMICAddressLookupComponent,
    WMICCustomTooltip,
    WMICCustomDropdownSelect,
    WMICBankAccountDetails,
    WMICRetrieveInfoContext,
    WMICProgressModal,
    WMICTimezoneUtilService
} from 'gw-capability-quoteandbind-common-react';
import { WMICOneIncPaymentModalQnB } from 'gw-capability-quoteandbind-ho-react';
import { WMICPaymentList } from 'gw-components-platform-react';
import {
    LocalDateUtil,
    ERROR_CODE,
    PaymentPlanID,
    PAYMENT_METHOD,
    WMICFeatureFlagUtil,
    WMICPaymentService,
    CONSTANTS,
    CONVENIENCE_FEES,
    WMICRichTextUtil,
    useDeferredPromise,
    FEE_TYPE
} from 'wmic-portals-utils-js';
import ReactDOM from 'react-dom';
import { WMICOneIncPaymentService } from 'wmic-capability-quoteandbind';

import WMICPolicySummaryUtil from './WMICPolicySummaryUtil';
import metadata from './WMICPolicySummary.metadata.json5';
import messages from './WMICPolicySummary.messages';
import styles from './WMICPolicySummary.module.scss';

dayjs.extend(utc);

const PAYMENT_METHOD_DROPDOWN_SELECT = 'paymentMethodSelect';
const WITHDRAWAL_DATE_MAX = 27;

const selectedPaymentMethodIsWire = (submissionVM) => {
    return _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === PAYMENT_METHOD.WIRE;
};

const selectedPaymentMethodIsAutoRecurring = (submissionVM) => {
    return _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === PAYMENT_METHOD.AUTO_RECURRING;
};

const selectedPaymentMethodIsCreditCard = (submissionVM) => {
    return _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === PAYMENT_METHOD.CREDIT_CARD;
};

function WMICPolicySummary(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM, updateWizardData, jumpTo, finish
    } = props;
    const history = useHistory();
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const retrieveContext = useContext(WMICRetrieveInfoContext);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const { phoneNumber } = appConfig;
    const { defer: deferOneIncLoadingPromise, deferRef: deferOneIncLoadingPromiseRef } = useDeferredPromise();

    const [isPageInitialized, setPageInitialized] = useState(false);

    const [paymentErrors, setPaymentErrors] = useState([]);
    const [hasDoubleAmountInstallment, setDoubleAmountInstallment] = useState({ value: false });
    const [twelvePayEnabled, setTwelvePayEnabled] = useState(true);
    const [twelvePayDisabledReason, setTwelvePayDisabledReason] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFinish, setFinish] = useState(false);
    const [ldFlags, setLdFlags] = useState({});
    const [consentedToOTW, setConsentedToOTW] = useState(false);
    const [showConsentRequiredMessage, setShowConsentRequiredMessage] = useState(false);
    const [otwConsentEnabled, setOtwConsentEnabled] = useState(false);

    const [isOneIncFeatureFlagAvailable, setIsOneIncFeatureFlagAvailable] = useState(false);
    const [makingOneIncPayment, updateMakingOneIncPayment] = useState(false);
    const [oneIncPaymentDetails, updateOneIncPaymentDetails] = useState({});
    const [oneIncPayNowEnabled, updateOneIncPayNowEnabled] = useState(false);
    const [oneIncSavePaymentEnabled, setOneIncSavePaymentEnabled] = useState(false);
    const [isOneIncPaymentComplete, setIsOneIncPaymentComplete] = useState(false);
    const [oneIncPaymentCompleteDetails, setOneIncPaymentCompleteDetails] = useState({});
    const [isPaymentModalClosed, setIsPaymentModalClosed] = useState(false);
    const [isOneIncPaymentMethodSaved, setIsOneIncPaymentMethodSaved] = useState(false);
    const [isPaymentModalLoaded, setIsPaymentModalLoaded] = useState(false);
    const [isBindingComplete, setIsBindingComplete] = useState(false);

    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const baseState = _.get(submissionVM, 'baseData.policyAddress.value.state');
    const serverInfo = _.get(submissionVM, 'serverInfo_WMIC.value');
    const [payer, updatePayer] = useState('');

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const emptyLabel = '.';
    const [labelContent, setLabelContent] = useState(emptyLabel);
    const [nameOfLabelClass, setNameOfLabelClass] = useState('wmicWizardInputLabel wmicInactive wmicLabelInitClass');

    const [showBottomFeeMessage, setShowBottomFeeMessage] = useState(false);
    const [paymentConvenienceFee, setPaymentConvenienceFee] = useState();
    const [processingFeeType, setProcessingFeeType] = useState();
    
    useEffect(() => {
        const params = {
            featureName: featureFlags.ONEINCPAYMENTS,
            regionCode: baseState,
            serverInfo
        };
        const oneIncAvailability = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params);
        
        setIsOneIncFeatureFlagAvailable(oneIncAvailability?.isAvailable);

        if (isOneIncFeatureFlagAvailable) {
            if (processingFeeType === 'flat') {
                setPaymentConvenienceFee(CONVENIENCE_FEES.ONEINC.flat);
            } else {
                setPaymentConvenienceFee(CONVENIENCE_FEES.ONEINC.percent);
            }
        } else {
            setPaymentConvenienceFee(CONVENIENCE_FEES.FIS);
        }

    }, [ldFlags, baseState, serverInfo, featureFlags.ONEINCPAYMENTS, isOneIncFeatureFlagAvailable, processingFeeType]);

    const getCustomQuote = (vm) => {
        const lobCoverages = _.get(vm, 'lobData.homeowners.offerings.value[0].coverages');
        const quoteOffering = _.get(vm, 'quoteData.offeredQuotes.value[0]');

        return {
            quote: quoteOffering,
            quoteID: vm.quoteID.value,
            sessionUUID: vm.sessionUUID.value,
            periodStart: vm.baseData.periodStartDate.value,
            periodEnd: vm.baseData.periodEndDate.value,
            coverages: lobCoverages
        };
    };

    const onPayerUpdate = (updatedPayer) => {
        updatePayer(updatedPayer);
    };

    const oneIncPaymentComplete = (details) => {
        setOneIncPaymentCompleteDetails(details);
        setIsOneIncPaymentComplete(true);
    };

    const updatePremium = useCallback(() => {
        const customQuote = getCustomQuote(submissionVM);
        const operation = CustomQuoteService.updateCustomQuote(customQuote, authHeader);
        operation.then(() => {
            // update payment plans
            const saveSubmissionPromise = LoadSaveService.updateQuotedSubmission(
                submissionVM.value,
                authHeader
            );
            saveSubmissionPromise.then((submission) => {
                submissionVM.value = submission;
                updateWizardData(submissionVM);
            });
        });
        operation.catch((error) => {
            const errorObj = error.baseError.data.error;
            if (errorObj && errorObj.data) {
                const appErrorCode = errorObj.data.appErrorCode.valueOf();
                switch (appErrorCode) {
                    case 601:
                    case 602:
                    case 603:
                    case 606:
                    case 608:
                    case 609:
                    case 610:
                        throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.TECHNICAL_ERROR_UPDATE_QUOTE };
                    case 600:
                        throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.INVALID_SESSION };
                    default:
                        throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.TECHNICAL_ERROR };
                }
            } else {
                throw { quoteID: submissionVM.quoteID.value, code: ERROR_CODE.TECHNICAL_ERROR };
            }
        });
    }, [CustomQuoteService, LoadSaveService, submissionVM, authHeader, updateWizardData]);

    useEffect(() => {
        if (isOneIncPaymentComplete) { 
            const bindPromise = LoadSaveService.bindSubmission(submissionVM.value);

            modalApi.showModal(
                <WMICProgressModal
                    modalTitle={translator(messages.processingPayment)}
                    isOpen
                    promise={bindPromise}
                />
            ).then((submission) => {
                if (oneIncPaymentCompleteDetails.saveModal) {
                    oneIncPaymentCompleteDetails.saveModal.close();
                }
                _.set(submissionVM, 'value', submission);
                updateWizardData(submissionVM);
                setIsBindingComplete(true);
            }).catch((error) => {
                if (error.code === 'DayTransitionError') {
                    updatePremium();
                } else if (error.code === 'ConsentError') {
                    setConsentedToOTW(false);
                }
                return error; // propagate error
            });
        }
    }, [isOneIncPaymentComplete])

    useEffect(() => {
        if (isBindingComplete && isPaymentModalClosed) { 
            finish();
        }
    }, [isBindingComplete, isPaymentModalClosed])

    const oneIncSaveComplete = (details) => {
        setIsOneIncPaymentMethodSaved(true);
        _.set(submissionVM, 'bindData.paymentDetails.tokenId.value', details.tokenId);
    };

    useEffect(() => {
        const filteredErrors = _.filter(submissionVM.value.systemMessages_WMIC, (msg) => {
            return msg.validationExceptionType === ERROR_CODE.PAYMENT || msg.validationExceptionType === ERROR_CODE.OTWCONSENT;
        });
        setPaymentErrors(filteredErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.value.systemMessages_WMIC)]);


    const selectedPaymentPlanIsFullPay = useCallback(() => {
        return _.get(submissionVM, 'bindData.selectedPaymentPlan.value') === PaymentPlanID.FULL_PAY;
    }, [submissionVM]);

    useEffect(() => {
        if (!selectedPaymentPlanIsFullPay() && selectedPaymentMethodIsCreditCard(submissionVM)) {
            setShowBottomFeeMessage(true);
        } else {
            setShowBottomFeeMessage(false);
        }
    }, [selectedPaymentPlanIsFullPay, submissionVM])

    const selectedPaymentPlanIsTwelveInstallments = useCallback(() => {
        return _.get(submissionVM, 'bindData.selectedPaymentPlan.value') === PaymentPlanID.TWELVE_INSTALLMENT;
    }, [submissionVM]);

    const getSelectedPaymentPlan = useCallback(() => {
        return _.find(_.get(submissionVM, 'bindData.paymentPlans.value'), { billingId: _.get(submissionVM, 'bindData.selectedPaymentPlan.value') }) || { total: {} };
    }, [submissionVM]);

    const isTwelvePayDisabledForReason = useCallback((reason) => {
        return reason === twelvePayDisabledReason;
    }, [twelvePayDisabledReason]);

    const isTwelvePayAndTwelvePayIsDisabledOutage = useCallback(() => {
        return selectedPaymentPlanIsTwelveInstallments() && !twelvePayEnabled && isTwelvePayDisabledForReason('outage');
    }, [isTwelvePayDisabledForReason, selectedPaymentPlanIsTwelveInstallments, twelvePayEnabled]);

    const isTwelvePayDisabledRegion = () => {
        return selectedPaymentPlanIsTwelveInstallments() && !twelvePayEnabled && !isTwelvePayDisabledForReason('outage');
    };

    const shouldShowPaymentDetails = () => {
        return !selectedPaymentPlanIsTwelveInstallments() || (twelvePayEnabled && selectedPaymentPlanIsTwelveInstallments()) || isTwelvePayAndTwelvePayIsDisabledOutage();
    };

    const getFeeTypeDetailsDTO = () => ({
        quoteID: _.get(submissionVM, 'quoteID.value')
    });

    const getPaymentDue = useCallback(() => {
        const currentPaymentPlan = getSelectedPaymentPlan();
        let tempPaymentPlan;

        if (currentPaymentPlan.billingId !== PaymentPlanID.TWELVE_INSTALLMENT) {
            tempPaymentPlan = {
                downPayment: {
                    amount: _.get(currentPaymentPlan, 'downPayment.amount'),
                    currency: _.get(currentPaymentPlan, 'downPayment.currency')
                }
            };

            if (processingFeeType === 'percent') {
                tempPaymentPlan.downPayment.amount += (tempPaymentPlan.downPayment.amount * _.get(paymentConvenienceFee, 'calcAmount'));
            } else {
                tempPaymentPlan.downPayment.amount += _.get(paymentConvenienceFee, 'amount');
            }
            
            return tempPaymentPlan;
        }

        return currentPaymentPlan;
    }, [getSelectedPaymentPlan, paymentConvenienceFee]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const init = useCallback(() => {
        submissionVM.bindData.paymentDetails.value = submissionVM.bindData.paymentDetails.value || {};
        submissionVM.bindData.paymentDetails.paymentMethod.value = submissionVM.bindData.paymentDetails.paymentMethod.value || '';
        submissionVM.bindData.paymentDetails.bankAccountData.value = submissionVM.bindData.paymentDetails.bankAccountData.value || {};
        const { addlNamedInsured } = submissionVM.baseData;
        const hasDapForNotification = submissionVM.bindData.hasDapForNotification_WMIC !== undefined ? submissionVM.bindData.hasDapForNotification_WMIC.value : false;
        if (hasDapForNotification) {
            submissionVM.bindData.dapPerson_WMIC.emailAddress1 = submissionVM.bindData.dapPersonEmailAddress_WMIC;
        }
        if (addlNamedInsured) {
            addlNamedInsured.emailAddress1 = submissionVM.baseData.addlNamedInsuredEmailAddress1;
            submissionVM.bindData.addlNamedInsuredPhone.value = addlNamedInsured.homeNumber;
        }
        const newSubmissionVM = viewModelService.clone(submissionVM);
        updateWizardData(newSubmissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const allPaymentMethods = useMemo(() => {
        return [
            {
                label: translator(messages.selectPaymentMethodOption),
                value: '',
                disabled: true,
                selected: true
            },
            {
                label: translator(messages.paymentMethodOneTime),
                value: PAYMENT_METHOD.WIRE
            },
            {
                label: translator(messages.paymentMethodCreditCard),
                value: PAYMENT_METHOD.CREDIT_CARD
            },
            {
                label: translator(messages.paymentMethodRecurring),
                value: PAYMENT_METHOD.AUTO_RECURRING
            }
        ];
    }, [translator])

    const getAvailablePaymentMethods = useMemo(() => {
        let paymentMethods = allPaymentMethods;
        switch (_.get(submissionVM, 'bindData.selectedPaymentPlan.value')) {
            case PaymentPlanID.FULL_PAY:
            case PaymentPlanID.THREE_PAY:
                paymentMethods = allPaymentMethods.filter((method) => {
                    return method.value !== PAYMENT_METHOD.AUTO_RECURRING;
                });
                break;
            case PaymentPlanID.TWELVE_INSTALLMENT:
                paymentMethods = allPaymentMethods.filter((method) => {
                    return method.value !== PAYMENT_METHOD.CREDIT_CARD && method.value !== PAYMENT_METHOD.WIRE;
                });
                break;
            default:
                paymentMethods = allPaymentMethods;
        }

        if (!_.chain(paymentMethods).map((method) => method.value).includes(_.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value', '')).value()) {
            _.set(submissionVM, 'bindData.paymentDetails.value', submissionVM.bindData.paymentDetails.value || {});
            _.set(submissionVM, 'bindData.paymentDetails.paymentMethod.value', '');
            const newSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newSubmissionVM);
        }

        if (selectedPaymentMethodIsAutoRecurring(submissionVM)) {
            setFinish(false);
        }

        return paymentMethods;
    }, [allPaymentMethods, submissionVM, updateWizardData, viewModelService]);

    const updatePaymentMethod = useCallback((value) => {
        if (value === PAYMENT_METHOD.WIRE) {
            const newSubmissionVM = WMICPolicySummaryUtil.resetPayerData('', submissionVM, viewModelService);
            updatePayer('');
            updateWizardData(newSubmissionVM);
            setFinish(true);
        } else {
            setFinish(false);
        }
        writeValue(value, 'bindData.paymentDetails.paymentMethod');
        setShowErrors(true);
    }, [writeValue, payer, submissionVM, viewModelService, updateWizardData]);

    const clearPayerData = useCallback(() => {
        submissionVM.value.bindData.paymentDetails.bankAccountData = {};
        submissionVM.value.bindData.paymentDetails.bankAccountData.publicID = null;
        submissionVM.value.bindData.paymentDetails.bankAccountData.firstName_WMIC = null;
        submissionVM.value.bindData.paymentDetails.bankAccountData.lastName_WMIC = null;
        submissionVM.value.bindData.paymentDetails.bankAccountData.email_WMIC = null;
        submissionVM.value.bindData.paymentDetails.bankAccountData.phoneNumber_WMIC = null;
        submissionVM.value.bindData.paymentDetails.bankAccountData.address_WMIC = {};
        submissionVM.value.bindData.paymentDetails.bankAccountData.bankABANumber = null;
        submissionVM.value.bindData.paymentDetails.bankAccountData.bankAccountNumber = null;

        const newSubmissionVM = viewModelService.clone(submissionVM);
        updateWizardData(newSubmissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const createPaymentMethodDropdown = useCallback(() => {
        const paymentMethods = getAvailablePaymentMethods;

        return (
            <div className="wmicDisplayFlex">
                <div className="wmicWizardInput wmicInputPosition">
                    <label htmlFor={PAYMENT_METHOD_DROPDOWN_SELECT} className={nameOfLabelClass}>
                        {labelContent}
                        <select
                            id={PAYMENT_METHOD_DROPDOWN_SELECT}
                            onChange={(event) => { updatePaymentMethod(event.target.value); }}
                            value={_.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value', '')}
                            disabled={isTwelvePayAndTwelvePayIsDisabledOutage()}
                            onFocus={() => {
                                setLabelContent(translator(messages.selectPaymentMethod));
                                setNameOfLabelClass('wmicWizardInputLabel wmicActive');
                            }}
                            onBlur={() => {
                                setNameOfLabelClass('wmicWizardInputLabel wmicInactive');
                            }}
                        >
                            {paymentMethods.map((paymentMethod) => {
                                return <option value={paymentMethod.value} disabled={paymentMethod.disabled} selected={paymentMethod.selected}>{paymentMethod.label}</option>;
                            })}
                        </select>
                    </label>
                    {showErrors && (
                        <div className="inline-messages clear">
                            {_.get(submissionVM, 'bindData.paymentDetails.paymentMethod.aspects.validationMessages', []).map((errorMessage) => {
                                return (
                                    <div>
                                        <span className="error-inline">{errorMessage}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }, [getAvailablePaymentMethods, isTwelvePayAndTwelvePayIsDisabledOutage, labelContent, nameOfLabelClass, showErrors, submissionVM, translator, updatePaymentMethod]);

    const determineMarpAvailability = () => {
        const params = {
            featureName: featureFlags.marp,
            regionCode: baseState,
            serverInfo
        };
        const response = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params);

        setTwelvePayEnabled(response.isAvailable);
        setTwelvePayDisabledReason(response.reason);
    }

    const determineOtwConsentAvailability = () => {
        const params = {
            featureName: featureFlags.OTWCONSENT,
            regionCode: baseState,
            serverInfo
        };
        const response = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params);

        setOtwConsentEnabled(response.isAvailable);
    }

    const formattedAmount = (currAmount, showDecimalAmount = true) => {
        const isPercent = currAmount?.type === FEE_TYPE.PERCENT;
        const amount = _.get(currAmount, 'calcAmount') ?? _.get(currAmount, 'amount');
        const currency = _.get(currAmount, 'currency', 'USD');

        const formatObj = isPercent ? {style: 'percent',roundingPriority: "morePrecision",minimumFractionDigits: 2,minimumSignificantDigits: 2}
            : { style: 'currency', currency: currency, currencyDisplay: 'symbol' };

        const formattedNumber = intl.formatNumber(
            amount,
            formatObj
        );

        return showDecimalAmount 
            ? formattedNumber
            : formattedNumber.replace(/(\.\d\d)$/g, '')
    }

    /**
     * Renders cell with either regular text value or formatted monetary value
     * @example 1000 => "$1,000.00", "1,000" => "$1,000.00", "Included" => "Included".
     * @param {object} item data for table cell
     * @param {number} index numeric index
     * @param {{id: string}} property data property to be rendered 
     * @returns {string} Correctly formatted monetary value or text string
     */
    const getCell = useCallback((item, index, { id: property }) => {
        const showDecimalAmount = property === 'premium';
        const cellValue = item[property];
        if (!Number.isNaN(+cellValue)) 
            return formattedAmount({amount: cellValue}, showDecimalAmount);
        
        if (Number.isNaN(+cellValue?.replace(/,/g, ''))) 
            return cellValue;
        
        return formattedAmount({amount: +cellValue?.replace(/,/g, '')}, showDecimalAmount)
    }, [formattedAmount])

    const updateTotal = (total) => {
        return hasDoubleAmountInstallment.value ? { amount: total.amount - 1, currency: total.currency } : total;
    };

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        init();
        initFeatureFlags();
        if (_.get(submissionVM, 'dateHasChanged_WMIC.value')) {
            const newDateTime = LocalDateUtil.toMidnightDate(_.get(submissionVM, 'baseData.periodStartDate.value'));
            const newDate = dayjs(newDateTime).format('MMMM D, YYYY');
            const message = WMICRichTextUtil.translateRichText(translator(messages.dateChangedModalMessage, { updatedEffectiveDate: newDate }));

            modalApi.showModal(
                <WMICModal
                    title={messages.dateChangedModalTitle}
                    message={message}
                    parseMessage
                    jumpTo={jumpTo}
                    iconClass="error-text fa-3x fa fa-exclamation-circle"
                />
            ).then(
                () => {
                    submissionVM.dateHasChanged_WMIC = null;
                    updateWizardData(submissionVM);
                    window.scrollTo(0, 0);
                    updatePremium(); // re-quote as date change might affect the rate
                }
            );
        }
        setPageInitialized(true);

        const feeTypeDetailsDTO = getFeeTypeDetailsDTO();

        WMICOneIncPaymentService.getFeeType(feeTypeDetailsDTO).then((result) => {
            setProcessingFeeType(result.feeType);
        });

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        determineMarpAvailability();
        determineOtwConsentAvailability();
    }, [ldFlags])

    const getCoveragesTable = useCallback(() => {
        const mainCoverages =  WMICPolicySummaryUtil.createDataForCoveragesTable(submissionVM, messages, translator);
        const specialLimitCoverages = WMICPolicySummaryUtil.createDataForSpecialLimitOfLiabilitiesCoveragesTable(submissionVM, messages, translator);
        return (
            <table className={cx('gw-table', styles.wmicCoverageTable, isMobile ? 'mobile-same-line' : '')}>
                <thead>
                    <tr>
                        <th colSpan='2'><span>{translator(messages.description)}</span></th>
                        <th><span>{translator(messages.limit)}</span></th>
                        <th><span>{translator(messages.premium)}</span></th>
                    </tr>
                </thead>
                {mainCoverages.map((cov, i) => {
                    return (
                        <tbody>
                            <tr>
                                <td colSpan='2' title={translator(messages.description)}><span>{getCell(cov, i, {id: 'description'})}</span></td>
                                <td title={translator(messages.limit)}><span>{getCell(cov, i, {id: 'limit'})}</span></td>
                                <td title={translator(messages.premium)}><span>{getCell(cov, i, {id: 'premium'})}</span></td>
                            </tr>
                        </tbody>
                    );
                })}
                {specialLimitCoverages.length > 0 && (
                    <tbody className={styles.wmicSpecialLimitCoverages}>
                        <tr><td colSpan='4' className='notitle'>{translator(messages.increasedSpecialLimitsOfLiabilityOnProperty)}</td></tr>
                        {specialLimitCoverages.map((cov, i) => {
                            return (
                                <tr className='special-limit-indent'>
                                    <td/>
                                    <td title={translator(messages.description)}><span>{getCell(cov, i, {id: 'description'})}</span></td>
                                    <td title={translator(messages.limit)}><span>{getCell(cov, i, {id: 'limit'})}</span></td>
                                    <td title={translator(messages.premium)}><span>{getCell(cov, i, {id: 'premium'})}</span></td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
        );
    }, [submissionVM, getCell, translator, isMobile]);

    const createDataForDeductibleTable = useCallback(() => {
        const toReturn = WMICPolicySummaryUtil.createDataForDeductibleTable(submissionVM,
            messages, translator);
        return toReturn;
    }, [submissionVM, translator]);

    const redirectToCCPayment = useCallback((paymentPlan) => {
        const params = {
            payAmount: paymentPlan.downPayment.amount,
            amount: paymentPlan.downPayment.amount,
            postalCode: submissionVM.value.baseData.accountHolder.postalCode,
            currency: paymentPlan.downPayment.currency,
            state: submissionVM.value.baseData.accountHolder.primaryAddress.state,
            city: submissionVM.value.baseData.accountHolder.primaryAddress.city,
            country: submissionVM.value.baseData.accountHolder.primaryAddress.country,
            email: submissionVM.value.baseData.accountHolder.emailAddress1,
            name: submissionVM.value.baseData.accountHolder.displayName,
            addressLine1: submissionVM.value.baseData.accountHolder.primaryAddress.addressLine1,
            userphone: submissionVM.value.baseData.accountHolder.phoneNumber,
            selectPaymentMethod: 'CREDIT_CARD'
        };
        ReactDOM.render(
            WMICPaymentService.createPaymentForm(params),
            document.getElementById('creditCardPaymentForm')
        );
    }, [submissionVM]);

    const onClickNextCreditCardPayment = useCallback(() => {
        const prebindRequest = LoadSaveService.preBindSubmission(submissionVM.value);
        return prebindRequest.then(() => {
            redirectToCCPayment(getSelectedPaymentPlan());
            return true;
        },
        (error) => {
            if (error.code === CONSTANTS.ERROR_CODE.DAY_TRANSITION) {
                updatePremium();
            }
            throw error;
        });
    }, [LoadSaveService, submissionVM.value, redirectToCCPayment, getSelectedPaymentPlan, updatePremium]);

    const makeOneIncPayment = useCallback ((paymentType, invoiceStream, msg) => {
        const payPlan = getPaymentDue();
        let paymentAmount = null;
        let oneIncPayerEmail = '';
        let oneIncPayer = '';

        const oneIncDetails = {
            submission: submissionVM,
            paymentAmount: ''
        };

        if (!selectedPaymentPlanIsTwelveInstallments()) {
            if (!selectedPaymentMethodIsCreditCard(submissionVM)) {                    
                oneIncPayerEmail = _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value');
                oneIncPayer = payer;
            } else if (selectedPaymentMethodIsCreditCard(submissionVM)) {  
                oneIncPayerEmail = _.get(submissionVM, 'bindData.payerEmail_WMIC.value');
                oneIncPayer = _.get(submissionVM, 'bindData.paymentDetails.payer.value');
                if (_.get(submissionVM, 'bindData.paymentDetails.payer.value') === 'other') {
                    oneIncPayerEmail = _.get(submissionVM, 'bindData.paymentDetails.email.value');
                }
            }
            paymentAmount = getSelectedPaymentPlan().downPayment.amount;   
        }
        if (paymentAmount === null) {
            paymentAmount = payPlan ? payPlan.downPayment.amount : null;
        }

        if (oneIncPayer !== 'other') {
            oneIncDetails.userDisplayName = _.get(submissionVM, 'bindData.payerFullName_WMIC.value');
            oneIncDetails.billingAddress = _.get(submissionVM, 'bindData.billingAddress.value');
            oneIncDetails.bankAccountFirstName = _.get(submissionVM, 'baseData.accountHolder.firstName.value');
            oneIncDetails.bankAccountLastName = _.get(submissionVM, 'baseData.accountHolder.lastName.value');
        } else {
            oneIncDetails.userDisplayName = '';
            oneIncDetails.billingAddress = undefined;
        }
            
        oneIncDetails.email = oneIncPayerEmail;
        oneIncDetails.paymentAmount = paymentAmount;
        oneIncDetails.paymentType = paymentType;  
        oneIncDetails.saveModal = msg;      
        oneIncDetails.invoiceStream = invoiceStream || '';
        oneIncDetails.payerPublicID = _.get(submissionVM, 'bindData.payerPublicID_Ext.value', undefined);
        
        updateOneIncPaymentDetails(oneIncDetails);
        updateOneIncPayNowEnabled(true);
        updateMakingOneIncPayment(true);
        

    }, [getPaymentDue, selectedPaymentPlanIsTwelveInstallments, submissionVM, getSelectedPaymentPlan, payer]);

    const paymentModalClosed = () => {
        setIsPaymentModalLoaded(false);
        updateMakingOneIncPayment(false);
        setIsPaymentModalClosed(true);
    };

    const showProgressDialog = (bindPromise) => {
        return(
            <WMICProgressModal
                modalTitle={translator(messages.loading)}
                isOpen
                promise={bindPromise}
            />
        );
    };

    const getDefaultWithdrawalDateOfMonth = () => {
        const periodStartDateTime = LocalDateUtil.toMidnightDate(_.get(submissionVM, 'baseData.periodStartDate.value'));
        let tempWithdrawalDateOfMonth = WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(periodStartDateTime);
        tempWithdrawalDateOfMonth = dayjs(tempWithdrawalDateOfMonth).add(1, 'day').date();
        return tempWithdrawalDateOfMonth > WITHDRAWAL_DATE_MAX ? 1 : tempWithdrawalDateOfMonth;
    };

    const onNextDefault = () => {
        setIsSubmitting(true);

        // Need to explicitly do these checks
        if (payer === undefined || payer === null || payer === "") {
            setShowErrors(true);
            
            return false;
        }

        if (!submissionVM.bindData.aspects.subtreeValid) {
            if (!(selectedPaymentPlanIsTwelveInstallments() && payer === CONSTANTS.DAP_PERSON_CAMEL_CASE)) {
                setShowErrors(true);
                return false;
            }
        }

        return submissionVM;
    };

    const onNextCustom = useCallback(() => {
        setIsSubmitting(true);
        if (selectedPaymentMethodIsWire(submissionVM) && ((!consentedToOTW && otwConsentEnabled) && !isOneIncFeatureFlagAvailable )) {
            setShowConsentRequiredMessage(true);
        }

        if (_.get(submissionVM, 'bindData.payerIsANI_WMIC.value') === true) {
            const payerEmail = _.get(submissionVM, 'bindData.payerEmail_WMIC.value');
            _.set(submissionVM, 'baseData.addlNamedInsuredEmailAddress1.value', payerEmail);
            _.set(submissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value', payerEmail);
            updateWizardData(submissionVM);
        }

        if (!submissionVM.bindData.aspects.subtreeValid) {
            setShowErrors(true);
            return false;
        }
        
        if (selectedPaymentPlanIsTwelveInstallments()) {
            if (submissionVM.bindData.paymentDetails.bankAccountData.withdrawalDateOfMonth_WMIC.value === undefined) {
                setShowErrors(true);
                return false;
            }
            
            const params = {
                featureName: featureFlags.marp,
                regionCode: baseState,
                serverInfo
            };
            const response = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params);

            setTwelvePayEnabled(response.isAvailable);
            setTwelvePayDisabledReason(response.reason);
            if (isTwelvePayAndTwelvePayIsDisabledOutage()) {
                modalApi.showModal(
                    <WMICModal
                        title={messages.serviceTemporarilyUnavailable}
                        message={translator(messages.onlineServiceUnavailable)}
                        description={WMICRichTextUtil.translateRichText(translator(messages.onlinePaymentPlanUnavailable, { phoneNumber: phoneNumber, quoteNumber: _.get(submissionVM, 'quoteID.value') }))}
                        iconClass="error-text fa-3x fa fa-info-circle"
                        actionBtnLabel={messages.continue}
                    />
                );
                return false;
            }

            const preBindPromise = LoadSaveService.preBindSubmission(submissionVM.value);
            return modalApi.showModal(
                <WMICProgressModal
                    modalTitle={translator(messages.savingSubmission)}
                    isOpen
                    promise={preBindPromise}
                />
            ).then((submission) => {
                if (submission.authorizationAttempts !== undefined) {
                    modalApi.showModal(
                        <WMICModal
                            title={messages.authorization}
                            message={translator(messages.authorizationAttempt, { authorizationAttempts: _.get(submission, 'authorizationAttempts.value') })}
                            iconClass="error-text fa-3x fa fa-exclamation-circle"
                        />
                    );
                    return false;
                }
                _.set(submissionVM, 'value', submission);
                updateWizardData(submissionVM);
                return submissionVM;
            }).catch((error) => {
                if (error.code === ERROR_CODE.DAY_TRANSITION) {
                    updatePremium();
                } else {
                    throw error;
                }
            });
        }

        if (selectedPaymentMethodIsCreditCard(submissionVM)) {
            
            if (!isOneIncFeatureFlagAvailable) {
                clearPayerData();

                return onClickNextCreditCardPayment().then(() => {
                    return submissionVM;
                });
            // eslint-disable-next-line no-else-return
            } else {
                const preBindPromise = LoadSaveService.preBindSubmission(submissionVM.value);
                const loadingModal = modalApi.showModal(
                    <WMICProgressModal
                        modalTitle={translator(messages.loading)}
                        isOpen
                        promise={deferOneIncLoadingPromise().promise}
                    />
                );
                
                return preBindPromise.then((submission) => {
                    makeOneIncPayment('credit', submission.bindData.invoiceStream_Ext, loadingModal);
                }).catch((error) => {
                    deferOneIncLoadingPromiseRef?.current?.reject();
                    if (error.code === ERROR_CODE.DAY_TRANSITION) {
                        updatePremium();
                    }
                    throw error;
                });
            }
        }

        if (selectedPaymentMethodIsWire(submissionVM) && ((otwConsentEnabled && consentedToOTW) || !otwConsentEnabled || isOneIncFeatureFlagAvailable)) {
            _.set(submissionVM.value, 'bindData.paymentDetails.bankAccountData.withdrawalDateOfMonth_WMIC', getDefaultWithdrawalDateOfMonth());
            updateWizardData(submissionVM);
            
            if ( !isOneIncFeatureFlagAvailable ) { 
                const bindPromise = LoadSaveService.bindSubmission(submissionVM.value);
                return modalApi.showModal(
                    <WMICProgressModal
                        modalTitle={translator(messages.processingPayment)}
                        isOpen
                        promise={bindPromise}
                    />
                ).then((submission) => {
                    _.set(submissionVM, 'value', submission);
                    updateWizardData(submissionVM);
                    return submissionVM;
                }).catch((error) => {
                    if (error.code === ERROR_CODE.DAY_TRANSITION) {
                        updatePremium();
                    } else if (error.code === ERROR_CODE.CONSENT) {
                        setConsentedToOTW(false);
                    }
                    throw error;
                });
            // eslint-disable-next-line no-else-return
            } else {
                const bindPromise = LoadSaveService.preBindSubmission(submissionVM.value);
                const loadingModal = modalApi.showModal(
                    <WMICProgressModal
                        modalTitle={translator(messages.loading)}
                        isOpen
                        promise={deferOneIncLoadingPromise().promise}
                    />
                );
                return bindPromise.then((submission) => {
                    makeOneIncPayment('bank', submission.bindData.invoiceStream_Ext, loadingModal);
                }).catch((error) => {
                    deferOneIncLoadingPromiseRef?.current?.reject();
                    if (error.code === ERROR_CODE.DAY_TRANSITION) {
                        updatePremium();
                    }
                    throw error;
                });
            }
        }
        return false;
    }, [LoadSaveService, baseState, clearPayerData, featureFlags.marp, isTwelvePayAndTwelvePayIsDisabledOutage, ldFlags, onClickNextCreditCardPayment, phoneNumber, selectedPaymentPlanIsTwelveInstallments, serverInfo, submissionVM, translator, updatePremium, updateWizardData, consentedToOTW, otwConsentEnabled, deferOneIncLoadingPromise, deferOneIncLoadingPromiseRef]);

    const getPurchaseButtonName = useCallback(() => {
        if (selectedPaymentMethodIsWire(submissionVM)) {
            return messages.purchaseNow;
        }
        if (selectedPaymentMethodIsCreditCard(submissionVM)) {
            return messages.proceedToPayment;
        } 
        return {
            id: 'quoteandbind.common.directives.CommonOfferingSelection.Next',
            defaultMessage: 'Next'
        }   
    }, [submissionVM]);

    const gotoContactUs = useCallback((displayMode) => {
        const nextState = {
            pathname: `/contact-us/${displayMode}`,
            state: {
                quoteID: submissionVM.value.quoteID
            }
        };
        history.push(nextState);
    }, [submissionVM, history]);

    const gotoPolicyInProcess = useCallback(() => {
        history.push({
            pathname: '/policyinprocess',
            search: `?quoteID=${submissionVM.value.quoteID}`
        });
    }, [submissionVM, history]);

    const paymentError = useCallback((redirectPage) => {
        // Close the loading modal
        deferOneIncLoadingPromiseRef?.current?.reject();
        if (redirectPage === 'policyInProcess') {
            return gotoPolicyInProcess();
        }
        return gotoContactUs(ERROR_CODE.TECHNICAL_ERROR);
    }, [gotoPolicyInProcess, gotoContactUs, deferOneIncLoadingPromiseRef]);

    const paymentModalLoaded = useCallback(() => {
        // Close the loading modal
        deferOneIncLoadingPromiseRef?.current?.resolve();
        setIsPaymentModalLoaded(true);
        setIsPaymentModalClosed(false);
    }, [deferOneIncLoadingPromiseRef]);

    const getMobileOverrides = () => {
        const deductibleOverrides = createDataForDeductibleTable().map(
            (element, index) => {
                return {
                    [`mobileDeductibleDescriptionTitle${index}`]: {
                        content: element.description
                    },
                    [`mobileDeductibleAmountContent${index}`]: {
                        content: WMICRichTextUtil.translateRichText(translator(messages.deductibleAmount, {amount: getCell(element, index, {id: 'amount'})}))
                    }
                };
            }
        );

        return Object.assign({}, ...deductibleOverrides);
    };

    const skipPageWhen = useCallback(() => {
        return new Promise(((resolve) => { resolve(retrieveContext.allowNavigationToRecurringPaymentAuthorization || retrieveContext.allowNavigationToRecurringPaymentDetails); }));
    }, [retrieveContext]);

    const handleConsentedToOTWClick = (value) => {
        // hide the message if it was shown and the checkbox was just checked
        if (showConsentRequiredMessage === true && value === true) {
            setShowConsentRequiredMessage(false);
        }
        setConsentedToOTW(value);
    };

    const overrideProps = {
        deductibleTable: {
            data: createDataForDeductibleTable(),
            visible: !isMobile
        },
        coveragesTable: {
            content: getCoveragesTable()
        },
        paymentPlansList: {
            submissionVM: submissionVM,
            hasDoubleAmountInstallment: hasDoubleAmountInstallment,
            isTwelvePayAndTwelvePayIsDisabledOutage: isTwelvePayAndTwelvePayIsDisabledOutage,
            isTwelvePayDisabledRegion: isTwelvePayDisabledRegion
        },
        paymentMethodContainer: {
            visible: !!_.get(submissionVM, 'bindData.selectedPaymentPlan.value')
        },
        paymentErrorsContainer: {
            className: paymentErrors.length > 0 ? 'control-group error ' : 'control-group'
        },
        paymentErrorsIterable: {
            data: paymentErrors
        },
        paymentOptionsContainer: {
            visible: shouldShowPaymentDetails()
        },
        paymentMethodContainerDiv: {
            content: createPaymentMethodDropdown()
        },
        paragraphWireNotFullPay: {
            visible: selectedPaymentMethodIsWire(submissionVM) && !selectedPaymentPlanIsFullPay()
        },
        paragraphNSFFee: {
            visible: selectedPaymentMethodIsWire(submissionVM) || selectedPaymentMethodIsAutoRecurring(submissionVM)
        },
        paragraphThreePayWithCreditCard: {
            visible: !selectedPaymentPlanIsFullPay() && selectedPaymentMethodIsCreditCard(submissionVM)
        },
        monthlyRecurringNoticeContainer: {
            visible: isTwelvePayDisabledRegion()
        },
        monthlyRecurringNoticeLabel: {
            content: translator(messages.monthlyRecurringNotice, { phoneNumber })
        },
        yourQuoteNumberLabel: {
            content: translator(messages.yourQuoteNumber, { quoteNumber: _.get(submissionVM, 'quoteID.value') })
        },
        totalPaymentDueTodayDiv: {
            visible: !selectedPaymentPlanIsTwelveInstallments()
        },
        totalPaymentDueTodayKey: {
            content: translator(messages.totalPaymentDueDate, { dueDate: intl.formatDate(new Date(), { year: 'numeric', month: 'long', day: 'numeric' }) })
        },
        totalPaymentDueTodayValueNotCreditCard: {
            visible: !selectedPaymentMethodIsCreditCard(submissionVM),
            showFractions: true,
            value: getSelectedPaymentPlan().downPayment
        },
        totalPaymentDueTodayValueCreditCard: {
            visible: selectedPaymentMethodIsCreditCard(submissionVM),
            content: `${formattedAmount(getPaymentDue().downPayment)}*`
        },
        creditCardFeeContainer: {
            visible: selectedPaymentMethodIsCreditCard(submissionVM)
        },
        creditCardFee: {
            content: translator(messages.creditCardFee, { convenienceFeeWithSymbol: formattedAmount(paymentConvenienceFee) })
        },
        policyTotalContainer: {
            visible: !isTwelvePayDisabledRegion()
        },
        policyTotalValueTwelveInstallments: {
            visible: selectedPaymentPlanIsTwelveInstallments(),
            content: `${updateTotal(formattedAmount(getSelectedPaymentPlan().total))} (${getSelectedPaymentPlan().name})`
        },
        policyTotalValue: {
            visible: !selectedPaymentPlanIsTwelveInstallments(),
            content: `${formattedAmount(getSelectedPaymentPlan().total)} (${getSelectedPaymentPlan().name})`
        },
        webConsentContainer: {
            visible: !isOneIncFeatureFlagAvailable && (selectedPaymentMethodIsWire(submissionVM) && otwConsentEnabled && payer !== '')
        },
        authorizationCheckbox: {
            label: translator(messages.iAgree),
            onValueChange: handleConsentedToOTWClick,
            value: consentedToOTW
        },
        authorizationError: {
            visible: showConsentRequiredMessage
        },
        redirectNotice: {
            visible: selectedPaymentMethodIsCreditCard(submissionVM)
        },
        mobileDeductiblesTableIterableContainer: {
            data: createDataForDeductibleTable(),
            visible: isMobile
        },
        wmicPremiumBoxSummary: {
            fullAmount: _.get(submissionVM, 'value.quoteData.offeredQuotes[0].premium.total')
        },
        wmicBankAccountDetails: {
            isOneIncFeatureFlagAvailable: isOneIncFeatureFlagAvailable,
            submissionVM: submissionVM,
            updateWizardData: updateWizardData,
            payer: payer,
            updatePayer: updatePayer,
            onPayerUpdate: onPayerUpdate,
            paymentMethod: _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value', ''),
            selectedPaymentMethodIsWire: selectedPaymentMethodIsWire,
            selectedPaymentMethodIsCreditCard: selectedPaymentMethodIsCreditCard,
            twelvePayDisabled: isTwelvePayAndTwelvePayIsDisabledOutage(),
            setFirstInstallmentIsDoubleAmount: setDoubleAmountInstallment,
            showErrors: showErrors,
            isSubmitting,
            twelvePayDisabledRegion: isTwelvePayDisabledRegion()
        },
        ...getMobileOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            getCell,
        },
        resolveComponentMap: {
            wmicPremiumBoxSummary: WMICPremiumBoxSummary,
            wmicInput: WMICCustomInput,
            wmicAddressLookupComponent: WMICAddressLookupComponent,
            wmicCustomRadioButton: WMICCustomRadioButton,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect,
            wmicBankAccountDetails: WMICBankAccountDetails,
            wmicPaymentList: WMICPaymentList,
            wmicCheckbox: WMICCheckbox
        },
        resolveClassNameMap: styles
    };

    if (!isPageInitialized) {
        return null;
    }
    const allowNormalFlow = selectedPaymentPlanIsTwelveInstallments() || !isOneIncFeatureFlagAvailable; // is the MAR page available

    return (
        <WizardPage
            skipWhen={skipPageWhen}
            finish={isFinish}
            showQuoteDisclaimer={false}
            showBottomFeeMessage={showBottomFeeMessage}
            bottomFeeMessageConvFeeWithSymbol={formattedAmount(paymentConvenienceFee)}
            onNext={onNextDefault}
            customWizardButton={{
                callbackFunction: onNextCustom,
                enabled: !allowNormalFlow,
                label: getPurchaseButtonName(),
                hideWizardNextButton: !allowNormalFlow
            }}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
            {makingOneIncPayment && (
                <WMICOneIncPaymentModalQnB
                    paymentModalClosed={paymentModalClosed}
                    paymentDetails={oneIncPaymentDetails}
                    payNowEnabled={oneIncPayNowEnabled}
                    savePaymentEnabled={oneIncSavePaymentEnabled}
                    saveComplete={oneIncSaveComplete}
                    paymentComplete={oneIncPaymentComplete}
                    paymentModalLoaded={paymentModalLoaded}
                    paymentError={paymentError}
                />
            )}
        </WizardPage>
    );
};

WMICPolicySummary.propTypes = wizardProps;
export default WMICPolicySummary;