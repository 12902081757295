import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WMICOneIncPaymentService } from 'wmic-capability-quoteandbind';
import { HasFeatureService }  from 'gw-capability-quoteandbind';
import appConfig from 'app-config';

import messages from './WMICOneIncPaymentModalQnB.messages';

const WMICOneIncPaymentModalQnB = (props) => {
    const modalApi = useModal();

    const {
        paymentModalLoaded,
        paymentModalClosed, 
        saveComplete, 
        paymentComplete, 
        paymentError,
        paymentDetails, 
        payNowEnabled,
        savePaymentEnabled
    } = props;

    const translator = useTranslator();
    const portalOne = useRef(null);
    let saveModal = null;
    let oneInc = null;
    const modalLoadedRef = useRef(false);

    const { oneIncSettings } = appConfig;
    const applicationFeatures = HasFeatureService.getApplicationFeatures();
    const agentquote = applicationFeatures.agentquoteandbind === 'true' ;
    const errorMessages = useMemo(() => {
        return {
            'default': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true,
                title: messages.cannotProceed,// POR-2061
                message: messages.cannotProceedMsg,
                description: agentquote ? 'quoteandbind.controllers.WizardFlowCtrl.underwriters.' +
                'Sorry, based on the data entered this quote cannot be completed online.'
                    : 'quoteandbind.controllers.WizardFlowCtrl.Sorry, based on the data entered this quote cannot be completed online.'
            },
            'DM': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true,
                title: messages.cannotProceed,// POR-2061
                message: messages.cannotProceedMsg,
                description: 'quoteandbind.controllers.WizardFlowCtrl.DM Issue.'
            },
            'UWIssue': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true,
                title: messages.contactUs,// POR-2061
                message: messages.contactUs,
                description: 'Hello, given the information provided we would like to personally discuss your available options within our range of insurance products.'+
                'At your earliest convenience, please give our Quote Specialists a call at 800-640-2920 to explore the benefits of Wawanesa Insurance today.'+
                '(Call Center Business Hours: Monday - Friday 8:00am - 3:00pm).'
            },
            'CLUEReport': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true,
                title: messages.contactUs,// POR-2061
                message: messages.contactUs,
                description: 'quoteandbind.controllers.WizardFlowCtrl.Hi there! Based on the information you\'ve provided, we\'d like to personally discuss your insurance options'
            },
            'PortalValidation': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true,
                title: messages.contactUs,// POR-2061
                message: messages.contactUs,
                description: 'quoteandbind.controllers.WizardFlowCtrl.Hi there! Based on the information you\'ve provided, we\'d like to personally discuss your insurance options'
            },
            'PaymentError': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true,
                title: messages.paymentDetailsFailure
            },
            'ConsentError': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: false,
                title: messages.paymentUnsuccessful,
                message: messages.paymentUnsuccessfulMsg
            },
            'AuthorizationError': {
                redirectState: 'policyInProcess',
                redirectStateIsErrorPage: true,
                title: messages.authorizationFailure
            },
            'DayTransitionError': {
                redirectState: 'policyInProcess',
                redirectStateIsErrorPage: true,
                title: messages.weHaveUpdated
            },
            'TwelvePayBindError': {
                redirectState: 'policyInProcess',
                redirectStateIsErrorPage: true,
                title: messages.confirmationOfPurchase
            },
            'InvalidSession': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: true
            },
            'PaymentSystemDown': {
                redirectState: 'contactUs',
                redirectStateIsErrorPage: false,
                title: messages.paymentSystemUnavailable,
                message: messages.paymentSystemUnavailableMsg
            }
        }
    }, [agentquote])

    const handleError = useCallback((error) => {
        let errorTitle = errorMessages.default.title;
        let errorMsg = errorMessages.default.message;
        let redirectPage = errorMessages.default.redirectState;
        if (error?.code === "PaymentError") {
            errorTitle = (errorMessages[error.code] || errorMessages.default).title;
            errorMsg = error.paymentErrors;
            redirectPage = (errorMessages[error.code] || errorMessages.default).redirectState;
        } else if (error?.length > 0 && error[0].validationExceptionType === "PaymentError") {
            errorTitle = (errorMessages[error[0].validationExceptionType] || errorMessages.default).title;
            errorMsg = error[0].issueMessage;
            redirectPage = (errorMessages[error[0].validationExceptionType] || errorMessages.default).redirectState;
        } 
        modalLoadedRef.current = true; // modal has loaded - in error though
        modalApi.showAlert({
            title: translator(errorTitle),
            message: translator(errorMsg),
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: translator(commonMessages.close)
        }).finally(() => paymentError(redirectPage));
    }, [errorMessages, paymentError, translator]);

    const onUnload = () => {
        paymentModalClosed();
    }

    const onError = (e) => {
        // TODO: use appropriate logger
        console.log(`error querying availability:${ e.detail }`);
    }

    const onPaymentComplete = (e) => {
        paymentComplete({details: {
            detail: e.detail,
            saveModal: saveModal
        }});
    }

    const onSaveComplete = (e) => {
        saveComplete({details: e.detail});
    }

    const onPaymentCanceled = (e) => {
        if (e.srcElement) {
            e.srcElement.innerHTML = '';
        }
        paymentModalClosed();
    }

    const onModalDialogOpened = () => {
        modalLoadedRef.current = true;
        paymentModalLoaded();
    }

    const initEventHandlers = useCallback((container) => {
        if (!container) {
            return;
        }

        container.addEventListener('portalOne.unload', onUnload);
        container.addEventListener('portalOne.error', onError);
        container.addEventListener('portalOne.paymentComplete', onPaymentComplete);
        container.addEventListener('portalOne.saveComplete', onSaveComplete);
        container.addEventListener('portalOne.paymentCanceled', onPaymentCanceled);
        container.addEventListener('portalOne.modalDialogOpened', onModalDialogOpened);
    }, []);

    useEffect(() => {  
        // eslint-disable-next-line react-hooks/exhaustive-deps
        oneInc = window.OneInc;
        const container = document.getElementById('portalOneContainer');
        portalOne.current = new oneInc.PortalOne(container, initEventHandlers);

        return () => {
            container.removeEventListener('portalOne.unload', onUnload);
            container.removeEventListener('portalOne.error', onError);
            container.removeEventListener('portalOne.paymentComplete', onPaymentComplete);
            container.removeEventListener('portalOne.saveComplete', onSaveComplete);
            container.removeEventListener('portalOne.paymentCanceled', onPaymentCanceled);
            container.removeEventListener('portalOne.modalDialogOpened', onModalDialogOpened);
        };
    }, []);

    const removeHttp = (url) => {
        return url.replace(/^https?:\/\//, '');
    };

    const modalLoadTimeout = useCallback(() => {
        setTimeout( () => {
            if (!modalLoadedRef.current) {
                handleError();
            }
        }, oneIncSettings.modalLoadTimeOut);
    }, [handleError, oneIncSettings.modalLoadTimeOut]);

    const getFeeTypeDetailsDTO = (pmtDetails) => {
        return {
            quoteID: _.get(pmtDetails, 'submission.quoteID.value')
        };
    };

    const getOneIncSessionDetailsDTO = (pmtDetails) => {
        return {
            bankAccountFirstName: pmtDetails.bankAccountFirstName,
            bankAccountLastName: pmtDetails.bankAccountLastName,
            payerPublicID: pmtDetails.payerPublicID,
            quoteID: _.get(pmtDetails, 'submission.quoteID.value'),
            sessionID: _.get(pmtDetails, 'submission.sessionUUID.value')
        };
    };

    // find where is oneIncPayment comming from
    useEffect(() => {
        if(payNowEnabled) {
            // this below is the payNow function:
            modalLoadTimeout();
            let paymentCategory;
            switch (paymentDetails.paymentType) {
                case 'credit':
                    paymentCategory = 'CreditCard';
                    break;
                case 'bank':
                    paymentCategory = 'ECheck';
                    break;
                default:
                    paymentCategory = 'CreditCard';
                    break;
            }
            // Store the saveModal so it can get passed back when payment is complete
            saveModal = paymentDetails.saveModal;

            const feeTypeDetailsDTO = getFeeTypeDetailsDTO(paymentDetails);
            const oneIncSessionDetailsDTO = getOneIncSessionDetailsDTO(paymentDetails);
            const billingAddressStreet = paymentDetails.billingAddress ? `${paymentDetails.billingAddress.addressLine1  }, ${  paymentDetails.billingAddress.city  }, ${  paymentDetails.billingAddress.state}` : '';
            const billingZip = paymentDetails.billingAddress ? paymentDetails.billingAddress.postalCode : '';

            WMICOneIncPaymentService.getFeeType(feeTypeDetailsDTO).then((result) => {
                if (!result.feeType) {
                    handleError(result.systemMessages);

                    return;
                }
            });

            WMICOneIncPaymentService.getOneIncSessionDetails(oneIncSessionDetailsDTO).then((result) => {
                if (!result.oneIncSessionID) {
                    handleError(result.systemMessages);
                    return;
                }
                const paymentObject = {
                    'sessionId': result.oneIncSessionID,
                    'paymentCategory': paymentCategory,
                    'feeContext': 'PaymentWithFee',
                    'convenienceFeeType': result.feeTypeValueOneInc_Ext,
                    'minAmountDue': paymentDetails.paymentAmount,
                    'accountBalance': paymentDetails.paymentAmount,
                    'amountContext': 'AmountDueOnly',
                    'billingZip': billingZip,
                    'billingAddressStreet': billingAddressStreet,
                    'policyHolderName': paymentDetails.userDisplayName,
                    'confirmationDisplay': 'true',
                    'saveOption': 'DoNotSave',
                    'allowClosing': 'true',
                    'displayMode': 'Modal',
                    'email': paymentDetails.email,
                    'clientReferenceData1': paymentDetails.invoiceStream,
                    'clientReferenceData2': paymentDetails.submission.quoteID.value,
                    'clientReferenceData4': 'QNB',
                    'clientReferenceData5': removeHttp(result?.pcUrl)
                };

                portalOne.current.makePayment(paymentObject);
            }, (error) => {
                handleError(error);
            });
        }
    }, [payNowEnabled])


    const savePaymentMethod = useCallback((pmtDetails) => {
        modalLoadTimeout();
        const oneIncSessionDetailsDTO = getOneIncSessionDetailsDTO(paymentDetails);

        WMICOneIncPaymentService.getOneIncSessionDetails(oneIncSessionDetailsDTO).then((result) => {
            if (!result.oneIncSessionID) {
                handleError(result.systemMessages);
                return;
            }
            const savePaymentObj = {
                customerId: result.oneIncCustomerID,
                sessionId: result.oneIncSessionID,
                acceptCreditCards: 'false',
                acceptPrepaidCards: 'false',
                paymentCategory: 'ECheck',
                policyHolderName: pmtDetails.userDisplayName,
                allowClosing: 'true',
                displayMode: 'Modal',
                email: pmtDetails.email,
                clientReferenceData2: pmtDetails.submission.quoteID.value,
                clientReferenceData4: 'QNB',
                clientReferenceData5: removeHttp(result.pcUrl)
            };

            portalOne.current.savePaymentMethod(savePaymentObj);
        }, (error) => {
            handleError(error);
        });
    }, [handleError, modalLoadTimeout, paymentDetails]);

    useEffect(() => {
        if(savePaymentEnabled){
            savePaymentMethod(paymentDetails);
        }
    }, [savePaymentEnabled, paymentDetails, savePaymentMethod]);

    return (
        <div id="portalOneContainer"/>
    );
};

WMICOneIncPaymentModalQnB.propTypes = {
    paymentDetails: PropTypes.shape({}).isRequired,
    paymentModalClosed: PropTypes.func.isRequired,
    saveComplete: PropTypes.func.isRequired,
    paymentModalLoaded: PropTypes.func.isRequired,
    payNowEnabled: PropTypes.bool.isRequired,
    savePaymentEnabled: PropTypes.bool.isRequired,
    paymentError: PropTypes.func.isRequired
};

WMICOneIncPaymentModalQnB.defaultProps = {
    paymentComplete: () => {},
    paymentModalLoaded: () => {},
    paymentError: () => {}
};

export default WMICOneIncPaymentModalQnB;