import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
    BrowserRouter as Router,
    Redirect
} from 'react-router-dom';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { useBreakpoint } from '@jutro/layout';
import { browserSupport } from 'wmic-portals-browserblocking-js';
import { ModalNextProvider, ModalNextEmitter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { routeConfirmationModal } from '@jutro/router';
// eslint-disable-next-line import/no-unresolved
import vmTranslator from '@xengage/gw-platform-translations';

import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { filterCapabilityRoutes } from '@xengage/gw-portals-config-js';
import {
    WMICCustomHeader,
    WMICCustomFooter,
    WMICErrorHandlingService,
    WMICESignatureCallbackPage,
    WMICQuickRetrieveQuotePage,
    WMICPaymentCallback
} from 'gw-capability-quoteandbind-common-react';

import {
    segmentationMap,
    segmentationComponentMap,
    Segmentation,
    SegmentationOption
} from 'gw-portals-segmentation-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent
} from 'gw-components-platform-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { GooglePlacesLookupComponentMap, GooglePlacesLookupComponents } from 'gw-capability-googleplaces-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { LoadSaveService, CustomQuoteService, MultiProductService } from 'gw-capability-quoteandbind';
import { PropertyCodeLookupService } from 'gw-capability-propertycode';
import { GTPWizard } from 'gw-capability-quoteandbind-gtp-react';
import { WMICOneIncPaymentService } from 'wmic-capability-quoteandbind';

import { BOPWizard } from 'gw-capability-quoteandbind-bop-react';
import { HOWizard, WMICKnockoutPage, WMICPolicyInProcessPage, WMICSuccessPage } from 'gw-capability-quoteandbind-ho-react';
import { PAWizard } from 'gw-capability-quoteandbind-pa-react';
import { SLQWizard } from 'gw-capability-quoteandbind-slq-react';
import FaqPage from 'gw-capability-faq-react';
import appConfig from 'app-config';

import { WMICApplicationUtil, APP_NAMES } from 'wmic-portals-utils-js';

import { WMICRegionUrlUtil } from 'wmic-portals-url-js';
import LandingPage from '../pages/LandingPage/LandingPage';

import WMICRetrievePage from '../pages/WMICRetrievePage/WMICRetrievePage';
import CustomLandingPage from '../pages/CustomLandingPage/CustomLandingPage';
import WMICContactUs from '../pages/WMICContactUs/WMICContactUs';
import appRoutes from './App.routes.metadata.json5';
import './App.messages';
import ApplicationHeader from '../components/ApplicationHeader/ApplicationHeader';

import { AppFloorPlan } from '@jutro/legacy/floorplan';

const modalEmitter = new ModalNextEmitter();

const { env, capabilitiesConfig } = appConfig;


const componentMap = {
    landingpage: LandingPage,
    retrievepage: WMICRetrievePage,
    gtpwizard: GTPWizard,
    bopwizard: BOPWizard,
    howizard: HOWizard,
    pawizard: PAWizard,
    slqwizard: SLQWizard,
    contactus: WMICContactUs,
    faqpage: FaqPage,
    successpage: WMICSuccessPage,
    knockoutpage: WMICKnockoutPage,
    signaturecallbackpage: WMICESignatureCallbackPage,
    quickretrievequote: WMICQuickRetrieveQuotePage,
    policyinprocess: WMICPolicyInProcessPage,
    paymentcallback: WMICPaymentCallback,
};

const renderCustomHeader = (props) => <ApplicationHeader {...props} />;

setComponentMapOverrides({
    ...platformComponentMap,
    ...AddressLookupComponentMap,
    ...VehicleInfoLookupComponentMap,
    ...GooglePlacesLookupComponentMap,
    ...segmentationMap,
    ...policyJobComponentMap,
    // replace the native IMG component with a proxied version
    img: { component: 'img' }
}, {
    ...platformComponents,
    ...AddressLookupComponents,
    ...VehicleInfoLookupComponents,
    ...GooglePlacesLookupComponents,
    ...segmentationComponentMap,
    ...policyJobComponents,
    img: ImageComponent
});

const App = () => {
    // eslint-disable-next-line no-unused-vars
    const translator = useTranslator();
    const [viewModelService, setViewModelService] = useState(undefined);

    const [regionLongName, setRegionLongName] = useState(WMICRegionUrlUtil.getRegionLongName());

    WMICApplicationUtil.setAppName(APP_NAMES.CE);
    useEffect(() => {
        if (window.self === window.top) {
            const theBody = document.getElementsByTagName('body')[0];
            theBody.className += ' wmicRoot';
        }
    }, [])



    const { breakpoint } = useBreakpoint({});
    const getLogoSrc = useCallback(() => {
        let logoName = "logo-default.svg";
        if(breakpoint === 'phone' || breakpoint === 'phoneWide'){
            logoName = "logo-mobile.svg";
        }
        const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');
        return `${prefix}/branding/${logoName}`;
    }, [breakpoint]);

    const floorPlansConfig = useMemo(() => {
        return [{
            className: "floorPlan",
            scrollContent: false,
            routes: filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes),
            showHeader: false,
            showFooter: false
        }];
    }, []);

    const getRegion = () => {
        let region = WMICRegionUrlUtil.getRegionByParam(window.location.search);
        if(!region) {
            region = WMICRegionUrlUtil.getRegion();
        }
        setRegionLongName(WMICRegionUrlUtil.getLongNameFromRegion(region));
    }

    useEffect(() => {
        browserSupport();
        getRegion();
    }, []);

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            const newViewmodeService = ViewModelServiceFactory.getViewModelService(result, translatorFn);
            setViewModelService(newViewmodeService);
            _.set(window, 'wmic.viewmodel.metadata', newViewmodeService.productMetadata);
        });

    }, [translator]);

    const handleError = useCallback((error = {}) => {
        const displayMode = error.code || 'TechnicalError';
        const state = {
            pathname: `/contact-us/${displayMode}`,
            state: {
                quoteID: error.quoteID,
                declineMessages: error.declineMessages
            }
        };
        return <Redirect to={state} />;
    }, []);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                LoadSaveService,
                CustomQuoteService,
                MultiProductService,
                PropertyCodeLookupService,
                WMICErrorHandlingService,
                getRegion,
                WMICOneIncPaymentService
            }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <Segmentation type="query-params">
                        <SegmentationOption defaultOption>
                            <Router
                                basename="/quote-and-buy"
                                getUserConfirmation={(message,callback) => routeConfirmationModal(message,callback,modalEmitter)}>
                                <ModalNextProvider>
                                    <WMICCustomHeader />
                                    <div className="main-wrapper qnbContent">
                                        <ErrorBoundary onError={handleError}>
                                            <AppFloorPlan
                                                componentMap={componentMap}
                                                floorPlans={floorPlansConfig}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                    <WMICCustomFooter regionLongName={regionLongName} />
                                </ModalNextProvider>
                            </Router>
                        </SegmentationOption>
                        <SegmentationOption is={[{ LP: '1' }, { LP: '2' }]}>
                            <CustomLandingPage />
                        </SegmentationOption>
                    </Segmentation>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
};

export default App;
